import { Image } from "primereact/image";
import "primeicons/primeicons.css";
import { Key } from "react";

const socialLinks = [
  {
    href: "https://bsky.app/profile/consularaffairs.bsky.social",
    src: "images/bluesky-logo.svg",
    alt: "bluesky",
  },
  { href: "https://www.facebook.com/consularaff", icon: "pi pi-facebook" },
  { href: "https://www.instagram.com/consularaff", icon: "pi pi-instagram" },
  {
    href: "https://pf.kakao.com/_LqVxas",
    src: "images/kakao-logo.svg",
    alt: "kakaotalk",
  },
  {
    href: "https://page.line.me/119urefi",
    src: "images/line-logo.png",
    alt: "line",
  },
  {
    href: "https://www.linkedin.com/company/consularaffairs",
    icon: "pi pi-linkedin",
  },
  {
    href: "https://www.linkedin.com/showcase/the-diplomatic-and-consular-networks-database-and-rankings/",
    icon: "pi pi-linkedin",
  },
  {
    href: "https://www.linkedin.com/showcase/passports-visas-and-migration-fraud/",
    icon: "pi pi-linkedin",
  },
  {
    href: "https://www.linkedin.com/showcase/honorary-consuls/",
    icon: "pi pi-linkedin",
  },
  { href: "https://t.me/consularaff", icon: "pi pi-telegram" },
  {
    href: "https://www.threads.net/@consularaff",
    src: "images/threads-logo.svg",
    alt: "threads",
  },
  {
    href: "https://invite.viber.com/?g2=AQB7rSDIpkQ9TFL5gf4PBrkj9WFggZlgngLkaqVh5jnjQfusXTT2z2ORfy%2FQYsm5",
    src: "images/viber-logo.svg",
    alt: "viber",
  },
  {
    href: "https://vk.com/consularaffairs",
    src: "images/vk-logo.svg",
    alt: "vk",
  },
  {
    href: "https://www.whatsapp.com/channel/0029Va9DnKvC6Zvia6uwnu3q",
    icon: "pi pi-whatsapp",
  },
  { href: "https://x.com/consularaff", src: "images/x-logo.svg", alt: "x" },
];

const renderSocialLink = (
  { href, src, alt, icon }: any,
  index: Key | null | undefined
) => (
  <a
    key={index}
    href={href}
    target="_blank"
    style={{ margin: "0 0.5rem", color: "black" }}
  >
    {src ? <Image src={src} alt={alt} width="16" /> : <i className={icon}></i>}
  </a>
);

export const MySocialNetworks = () => {
  return (
    <div className="flex justify-content-center mt-2">
      {socialLinks.map(renderSocialLink)}
    </div>
  );
};
