import React, { Component } from "react";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
import { AddPageHeader } from "../Common/Utils";

class About extends Component {
  render() {
    return (
      <div>
        {AddPageHeader("About")}
        <div className="reduce-width">
          <Divider align="center">
            <h1>About</h1>
          </Divider>
          <div className="privacy-terms">
            <p>
              Incubated by Yonsei University, the Consular Affairs Center is a
              nongovernmental and nonpartisan research institute committed to
              consular affairs and diplomatic networks.
            </p>
            <p>
              Our mission is twofold: advancing research and fostering practical
              application within both fields. Our platforms and deliverables
              cater to a diverse audience, spanning academic, corporate,
              governmental, and nonprofit organizations, as well as individuals
              such as emigrants, tourists, and travelers. Operating from
              multiple countries, the Center has a global reach through its
              network of correspondents and partners.
            </p>
            <p>
              <b>Origins</b>
            </p>
            <p>
              The inception of the Center stemmed from three principal
              observations. Firstly, it recognized the substantial budgetary
              allocations and workforce dedicated to consular affairs at both
              the central administration and overseas posts levels. Secondly, it
              noted the growing involvement of diverse stakeholders in consular
              diplomacy, and their multifaceted interactions with diplomatic
              networks. Thirdly, it identified a notable gap: the absence of a
              dedicated nongovernmental organization addressing these fields and
              questions.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
