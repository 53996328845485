import React, { useState, useEffect } from "react";
import SocialNetworksCarousel from "./SocialNetworksCarousel";
import { CountryService } from "../../services/CountryService";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import NewsTable from "../Common/NewsTable";
import { MySocialNetworks } from "../Common/MySocialNetworks";
import VisualCarousel from "./VisualCarousel";
import HonoraryDirectoryTable from "../Common/HonoraryDirectoryTable";
import LawRegulationsTable from "../Common/LawRegulationsTable";
import OfficialDirectoryTable from "../Common/OfficialDirectoryTable";
import { AddHomePageHeader } from "../Common/Utils";
import JobsTable from "../Common/JobsTable";

const Home = () => {
  const [news, setNews] = useState([]);
  const [bibliography, setBibliography] = useState([]);
  const [honoraryBibliography, setHonoraryBibliography] = useState([]);
  const [honoraryNews, setHonoraryNews] = useState([]);
  const [notice, setNotice] = useState([]);
  const [laws, setLaws] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [countries, setCountries] = useState([]);
  const [honoraryCorpsDirectory, setHonoraryCorpsDirectory] = useState([]);
  const [officialdirectory, setOfficialDirectory] = useState([]);

  const countryService = new CountryService();

  useEffect(() => {
    countryService.getNews(5).then((data) => setNews(data));
    countryService.getBibliography(5).then((data) => setBibliography(data));
    countryService
      .getHonoraryBibliography(5)
      .then((data) => setHonoraryBibliography(data));
    countryService.getHonoraryNews(5).then((data) => setHonoraryNews(data));
    countryService.getJobs(5).then((data) => setJobs(data));
    countryService.getCountriesStatistics(5).then((data) => setCountries(data));
    countryService
      .getHonoraryCorpsDirectory(5)
      .then((data) => setHonoraryCorpsDirectory(data));
    countryService.getNotice(5).then((data) => setNotice(data));
    countryService.getLaws(5).then((data) => setLaws(data));
    countryService
      .getOfficialDirectory(5)
      .then((data) => setOfficialDirectory(data));
  }, []);

  const countryTemplate = (rowData) => {
    return (
      <div style={{ display: "flex" }}>
        <img
          src={"flags/" + rowData.code.toLowerCase() + ".svg"}
          alt=""
          width="17em"
          style={{ marginRight: "0.7em" }}
        />
        {rowData.name}
      </div>
    );
  };

  return (
    <div className="reduce-width">
      {AddHomePageHeader()}
      <Divider align="center">
        <h1>
          Research institute committed to consular affairs and diplomatic
          networks
        </h1>
      </Divider>
      <div>
        <div className="home-row">
          <div className="home-col11">
            <VisualCarousel />
          </div>
          <div className="home-col12">
            <h3 className="section-title">
              Real-time ranking highlights{" "}
              <Link to="/country-rankings" style={{ color: "black" }}>
                <i className="pi pi-external-link"></i>
              </Link>
            </h3>
            <DataTable value={countries} responsiveLayout="scroll" rows={3}>
              <Column
                field="name"
                header="Country"
                body={countryTemplate}
              ></Column>
              <Column
                field="embassies"
                header="Overseas embassies or high commissions"
              ></Column>
            </DataTable>
          </div>
        </div>
        <Divider />
        <div className="home-row">
          <div className="home-col21">
            <h3 className="section-title">
              Bibliography{" "}
              <Link to="/bibliography" style={{ color: "black" }}>
                <i className="pi pi-external-link"></i>
              </Link>
            </h3>
            <DataTable value={bibliography} responsiveLayout="scroll">
              <Column field="year" header="Year" sortable></Column>
              <Column field="title" header="Title" sortable></Column>
              <Column field="author" header="Author" sortable></Column>
            </DataTable>
          </div>
          <div className="home-col22">
            <h3 className="section-title">
              Daily press review{" "}
              <Link to="/daily-press-review" style={{ color: "black" }}>
                <i className="pi pi-external-link"></i>
              </Link>
            </h3>
            <NewsTable data={news} />
          </div>
          <div className="home-col23">
            <div className="home-social-networks">
              <h3 className="section-title">
                5 news and 1 bibliographic extract daily
              </h3>
              <div className="mb-3">{MySocialNetworks()}</div>
              <SocialNetworksCarousel />
            </div>
          </div>
        </div>
        <Divider />
        <div className="home-row">
          <div className="home-col21">
            <h3 className="section-title">
              Laws, regulations, and policies{" "}
              <Link
                to="/laws-regulations-and-policies"
                style={{ color: "black" }}
              >
                <i className="pi pi-external-link"></i>
              </Link>
            </h3>
            <LawRegulationsTable data={laws} />
          </div>
          <div className="home-col22">
            <h3 className="section-title">
              Diplomatic directory{" "}
              <Link to="/diplomatic-directory" style={{ color: "black" }}>
                <i className="pi pi-external-link"></i>
              </Link>
            </h3>
            <OfficialDirectoryTable data={officialdirectory} />
          </div>
          <div className="home-col23">
            <h3 className="section-title">
              Notice{" "}
              <Link to="/notice" style={{ color: "black" }}>
                <i className="pi pi-external-link"></i>
              </Link>
            </h3>
            <DataTable value={notice} responsiveLayout="scroll">
              <Column field="date" header="Date" sortable></Column>
              <Column field="title" header="Title" sortable></Column>
            </DataTable>
          </div>
        </div>
        <Divider />
        <div className="home-row">
          <div className="home-col21">
            <h3 className="section-title">
              Honorary bibliography{" "}
              <Link to="/honorary-bibliography" style={{ color: "black" }}>
                <i className="pi pi-external-link"></i>
              </Link>
            </h3>
            <DataTable value={honoraryBibliography} responsiveLayout="scroll">
              <Column field="year" header="Year" sortable></Column>
              <Column field="title" header="Title" sortable></Column>
              <Column field="author" header="Author" sortable></Column>
            </DataTable>
          </div>
          <div className="home-col22">
            <div className="home-social-networks">
              <h3 className="section-title">
                Honorary jobs{" "}
                <Link to="/honorary-jobs" style={{ color: "black" }}>
                  <i className="pi pi-external-link"></i>
                </Link>
              </h3>
              <JobsTable data={jobs} />
            </div>
          </div>
          <div className="home-col23">
            <h3 className="section-title">
              Honorary news{" "}
              <Link to="/honorary-news" style={{ color: "black" }}>
                <i className="pi pi-external-link"></i>
              </Link>
            </h3>
            <NewsTable data={honoraryNews} />
          </div>
        </div>
        <Divider />
        <div className="home-row">
          <div className="home-full-col">
            <h3 className="section-title">
              Honorary corps directory{" "}
              <Link to="/honorary-corps-directory" style={{ color: "black" }}>
                <i className="pi pi-external-link"></i>
              </Link>
            </h3>
            <HonoraryDirectoryTable data={honoraryCorpsDirectory} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
